import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  DescriptionContents,
  TitleContents,
} from '../../../styles/styled.layout';



export const WeAreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: ${Sizes.desktopPaddingVertical};
  background-color: ${Colors.appBackground};
`;

export const WeAreTitlesView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const WeAreTitle = styled(TitleContents)`
`;



export const WeAreContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 70px;
`;


export const WeAreLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WeAreItems = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const WeAreItIcon = styled.div`
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
  margin-right: 25px;
  background-color: ${Colors.buttonOpacity};
  border-radius: 50%;
`;

export const WeAreItInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WeAreItInfoTitle = styled(DescriptionContents)`
  margin-top: 0;
  color: ${Colors.white};
  font-weight: bold;
`;

export const WeAreItInfoText = styled.p`
  margin-top: 10px;
  color: ${Colors.muted};
  font-size: 16px;
`;


export const WeAreRight = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 550px;
  gap: 30px 10px;
`;

export const WeAreNumber = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 190px;
`;

export const WeAreNuIcon = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  background-color: ${Colors.buttonOpacity};
  border-radius: 50%;
`;

export const WeAreNuInfoTitle = styled.h3`
  color: ${Colors.white};
  font-size: 28px;
  text-align: center;
`;

export const WeAreNuInfoText = styled.p`
  color: ${Colors.white};
  font-size: 20px;
  text-align: center;
`;
