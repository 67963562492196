import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import ContainerContact from '../../components/Containers/ContainerContact';
import ContentAbout from '../../components/Containers/ContentAbout';
import LandingAboutScreen from '../../components/Containers/LandingAbout';

import FooterApp from '../../components/Footers/FooterApp';
import NavbarLanding from '../../components/Navbar/NavbarLanding';
import TreeScreens from '../../components/TreeScreens';
import NameRoutes from '../../navigation/names';

import {
  Images,
  Videos,
} from '../../shared/constants';

import SectionCities from './SectionCities';
import SectionOurNumbers from './SectionOurNumbers';
import SectionWeAre from './SectionWeAre';
import SectionYears from './SectionYears';



const AboutScreen: React.FC = () => {
  const history = useHistory();


  const navItemsPage = [
    {
      navTitle: 'Início',
      navHref: '#page_initial',
    },
    {
      navTitle: 'Quem somos',
      navHref: '#we-are',
    },
    {
      navTitle: 'Uma noite melhor',
      navHref: '#a-better-night',
    },
    {
      navTitle: 'Números',
      navHref: '#numbers',
    },
    {
      navTitle: 'Imprensa',
      navHref: '#press',
    },
    {
      navTitle: 'Sobre nós',
      navHref: '#about_us',
    },
  ];



  return (

    <NavbarLanding
      navItems={navItemsPage}>

      <LandingAboutScreen
        id={'page_initial'}
        video={Videos.noitadaAboutVideo}
        title={'Pensou Noitada, pensou em referência em plataforma online na América Latina'}
        description={'Tecnologia é o nosso talento e entretenimento a nossa paixão'}
      />



      <SectionWeAre
        id={'we-are'}
      />



      <ContentAbout
        id={'a-better-night'}
        title={'Uma noite melhor'}
        subtitle={'É através da noite que vamos mudar o dia de milhões de pessoas! Conheça nossas ações com clientes e parceiros.'}
        backgroundImage={Images.backgroundVolunteer}
      // buttonOneText={'Saiba mais'}
      // buttonOnePress={() => {
      //   history.push(NameRoutes.ABetterNightScreen);
      // }}
      />



      <SectionOurNumbers
        id={'numbers'}
      />



      <ContentAbout
        id={'press'}
        title={'Imprensa'}
        subtitle={'Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor'}
        backgroundImage={Images.backgroundIndex2}
        buttonOneText={'Saiba mais'}
        buttonOnePress={() => {
          history.push(NameRoutes.PressScreen);
        }}
      />



      <SectionYears
      />



      <SectionCities
      />



      <ContainerContact
      />



      <TreeScreens id={'about_us'} />
      <FooterApp />

    </NavbarLanding>

  );
};



export default AboutScreen;
