import _aboutYears from '../assets/svgs/about-years.svg';
import _playButton from '../assets/svgs/play-button.svg';



export const SVG_IMAGES = {
  aboutYears:                       _aboutYears,
};


export const SVG_ITEMS = {
  playButton:                       _playButton,
};



const Svgs = {
  ...SVG_IMAGES,
  ...SVG_ITEMS,
};


  
export default Svgs;
