import styled,
{
  keyframes,
} from 'styled-components';

import {
  Colors,
} from '../../../shared/constants';



const moveUpAndDown = keyframes`
  0% {
  opacity: 1;
  }

  100% {
  opacity: 0;
  }
`;



export interface IProps {
  type1?: boolean;
  delay?: string;
}



export const Astyles_mercado__j7V5j = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 30px;
  box-shadow: inset 0 24px 50px 0 rgba(0,0,0,.35);
  background: linear-gradient(135deg, ${Colors.primaryDark}, ${Colors.primary} 75%, ${Colors.secondary});
`;

export const Astyles_mercado__wrapper__5ko_U = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Astyles_mercado__title__JXHB_ = styled.p`
  color: ${Colors.white};
  font-size: 82px;
  font-weight: 700;
  letter-spacing: -3.28px;
  line-height: 100%;
  text-align: left;
`;

export const Astyles_rotating__text__i37fz = styled.div`
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -3.28px;
  text-align: left;
  font-size: 82px;
  scroll-margin-top: 93px;
  position: relative;
  display: inline-flex;
  height: 70px;
  width: 200px;
`;

export const Astyles_word__kIEDv1 = styled.p`
  display: inline-flex;
  overflow: hidden;
  position: absolute;
  width: 100%;
  color: ${Colors.primary};
  font-size: 82px;
  font-weight: 700;
  letter-spacing: -3.28px;
  text-align: left;
`;

export const AnimatedLetter = styled.span`
  display: inline-block;
  animation: ${moveUpAndDown} 1.5s infinite; 
  animation-delay: ${(props: IProps) => props.delay}; 
  color: ${Colors.accent};
  transition: all 1.5s ease-in;
`;

export const AnimatedLetter1 = styled.span`
  display: inline-block;
`;


export const Astyles_mercado__icon__lj8Qu = styled.div`
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -3.28px;
  text-align: left;
  font-size: 82px;
  width: 22;
  height: 24;
  fill: none;
  scroll-margin-top: 93px;
  display: block;
  position: absolute;
  left: auto;
  right: 0;
  top: .25rem;
`;

export const SectionYearImage = styled.img`
  width: 100%;
  max-height: 174px;
  max-width: 552px;
  aspect-ratio: auto 552 / 174;
  margin-left: 24px;
  object-fit: contain;
`;

export const Astyles_mercado__line__hMg3J = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 1px;
  width: 80%;
  max-width: 930px;
  background-color: ${Colors.white};
`;

export const ADstyles_mercado__description__onVKz = styled.p`
  text-align: center;
  scroll-margin-top: 93px;
  margin: 0;
  padding-top: 1.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 60px;
  font-size: 26px;
`;
