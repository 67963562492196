import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  PlatformDevices,
} from '../../../shared/utils/platforms.utils';

import {
  DescriptionContents,
  PaddingContainer,
  TitleContents,
} from '../../../styles/styled.layout';



export const ToolsInfoContainer = styled(PaddingContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, ${Colors.linearTopApp}, ${Colors.linearBottomApp});
`;

export const ToolsTitlesView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const ToolsTitle = styled(TitleContents)`
  text-align: center;
`;

export const ToolsDescription = styled(DescriptionContents)`
  text-align: center;
`;



export const ToolsSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 850px;
  margin-top: ${Sizes.marginFromTitles};
  margin-bottom: ${Sizes.marginFromTitles};
  padding-top: ${Sizes.marginFromTitles};
  padding-bottom: ${Sizes.marginFromTitles};
  gap: 40px 0;
`;

export const ToolsItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 130px;
  padding-left: 25px;
  padding-right: 25px;

  @media ${PlatformDevices.maxMobileS} {
    width: 100%;
  }

  @media ${PlatformDevices.minMobileS} {
    width: 50%;
  }

  @media ${PlatformDevices.minTablet} {
    width: 25%;
  }
`;

export const ToolsItemsImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 75px;
  height: 75px;
  margin-bottom: 13px;
  border-radius: 5px;
`;

export const ToolsItemsInfoTitle = styled.h2`
  color: ${Colors.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;



export const FooterDescriptionText = styled(TitleContents)`
  margin-bottom: 60px;
  font-size: 53px;
  text-align: center;
`;



export const ToolActionButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ToolActionImageView = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
`;


export const ImageTypesIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;


export const ToolActionTitle = styled(TitleContents)`
  color: ${Colors.accent};
  font-size: 22px;
  text-align: center;
`;

export const ToolActionText = styled(DescriptionContents)`
  text-align: center;
`;
