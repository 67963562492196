import React from 'react';

import {
  useHistory,
} from 'react-router-dom';

import NameRoutes from '../../../navigation/names';

import {
  Images,
} from '../../../shared/constants';

import {
  ToolsInfoContainer,
  ToolsTitlesView,
  ToolsTitle,
  ToolsDescription,
  ToolsSection,
  ToolsItemsContent,
  ToolsItemsImageContent,
  ToolsItemsInfoTitle,
  FooterDescriptionText,
  ToolActionButton,
  ToolActionImageView,
  ImageTypesIcon,
  ToolActionTitle,
  ToolActionText,
} from './styled';



export interface IProps {
  id?: string;
}



const ContentBusinessTools: React.FC<IProps> = (props) => {
  const history = useHistory();



  const itemsTools = [
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools01}
        />
      ),
      description: 'Gerenciamento de Cardápio Digital, taxas e mais',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools02}
        />
      ),
      description: 'Impressão automática de pedidos, comandas e outras ferramentas',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools03}
        />
      ),
      description: 'Sistema PDV Para a frente de caixa',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools04}
        />
      ),
      description: 'Estoque e alertas em tempo real com a quantidade dos itens disponíveis',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools05}
        />
      ),
      description: 'Compartilhamento com diferentes níveis de acesso',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools06}
        />
      ),
      description: 'Diferentes relatórios e informações',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools07}
        />
      ),
      description: 'Dados do seu público e suas preferências',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools08}
        />
      ),
      description: 'Vendas com diversos métodos de pagamentos',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools09}
        />
      ),
      description: 'Facilidade para receber vendas',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools10}
        />
      ),
      description: 'Gestão de eventos, reservas, comanda e muito mais',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools11}
        />
      ),
      description: 'Check-In e Check-Out online',
    },
    {
      icon: (
        <ImageTypesIcon
          src={Images.tools12}
        />
      ),
      description: 'Plataforma em diferentes idiomas',
    },
  ];



  return (

    <ToolsInfoContainer
      id={props.id}>

      <ToolsTitlesView>

        <ToolsTitle>
          {('Algumas de nossas ferramentas').toUpperCase()}
        </ToolsTitle>


        <ToolsDescription>
          {'A Noitada foi pensada e desenvolvida para ajudar o seu negócio!'}
        </ToolsDescription>

      </ToolsTitlesView>



      <ToolsSection>
        {itemsTools?.map((itemGrid: any) => (

          <ToolsItemsContent>

            <ToolsItemsImageContent>
              {itemGrid.icon}
            </ToolsItemsImageContent>


            <ToolsItemsInfoTitle>
              {itemGrid.description}
            </ToolsItemsInfoTitle>

          </ToolsItemsContent>

        ))}
      </ToolsSection>



      <FooterDescriptionText>
        {'E muito Mais!'.toUpperCase()}
      </FooterDescriptionText>



      <ToolActionButton
        onClick={() => {
          history.push(NameRoutes.ContactScreen);
        }}>

        <ToolActionImageView>
          <ImageTypesIcon
            src={Images.toolsPlus}
          />
        </ToolActionImageView>



        <ToolActionTitle>
          {'Não encontrou a ferramenta que buscava?'.toUpperCase()}
        </ToolActionTitle>



        <ToolActionText>
          {'Entre em contato. Estamos aqui para ajudar você!'}
        </ToolActionText>

      </ToolActionButton>

    </ToolsInfoContainer>

  );
};



export default ContentBusinessTools;
