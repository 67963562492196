export const FILES_DOWNLOAD = {
  logosDownloadLink:                'https://download.club',
  iconDownloadLink:                 'https://download.club',
  screensDownloadLink:              'https://download.club',
  imagesDownloadLink:               'https://download.club',
};


export const NOITADA_CONTACT = {
  whatsAppNumber:                   '5571992742085',
  whatsAppMessage:                  'Olá! Eu gostaria de mais informações sobre a Noitada. Poderia me ajudar?',

  messengerUsername:                'noitadaBRA',
  messengerIDUsername:              '636260263204734',
  messengerLink:                    'https://www.messenger.com/t/636260263204734',
};


export const NOITADA_SOCIAL = {
  facebookUsername:                 'noitadaBRA',
  facebookIDUsername:               '636260263204734',
  facebookLink:                     'https://www.facebook.com/noitadaBRA',

  instagramUsername:                'noitadaBRA',
  instagramLink:                    'https://www.instagram.com/_u/noitadaBRA',
  
  twitterUsername:                  'noitadaBRA',
  twitterIDUsername:                '837020906467766272',
  twitterLink:                      'https://www.twitter.com/noitadaBRA',

  linkedInLink:                     'https://www.linkedin.com/company/noitada',

  youtubeChannel:                   '@noitadaBRA',
  youtubeLink:                      'https://www.youtube.com/@noitadaBRA',

  threadsLink:                      'https://www.threads.net/@noitadabra',

  tiktokLink:                       'https://www.tiktok.com/@noitadabra',

  noitadaEmailContact:              'mailto:noitadabra@gmail.com',

  jobsLink:                         'https://www.linkedin.com/company/noitada/jobs',
};


export const PERSAS_SOCIAL = {
  persasSiteLink:                   'https://persas.club',
};


export const NOITADA_STORES = {
  noitadaMobileAppAndroidLink:      'https://play.google.com/store/apps/details?id=com.noitada',
  noitadaMobileAppIosLink:          'https://apps.apple.com/us/app/noitada/id1455081329',
  
  organizerMobileAppAndroidLink:    'https://play.google.com/store/apps/details?id=com.organizer',
  organizerMobileAppIosLink:        'https://apps.apple.com/us/app/noitada/id1445211329',
};



const Keys = {
  ...FILES_DOWNLOAD,
  ...NOITADA_CONTACT,
  ...NOITADA_SOCIAL,
  ...PERSAS_SOCIAL,
  ...NOITADA_STORES,
};



export default Keys;
