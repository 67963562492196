import React from 'react';

import {
  Svgs,
} from '../../../shared/constants';

import {
  Astyles_mercado__j7V5j,
  Astyles_mercado__wrapper__5ko_U,
  Astyles_mercado__title__JXHB_,
  Astyles_rotating__text__i37fz,
  Astyles_word__kIEDv1,
  Astyles_mercado__icon__lj8Qu,
  SectionYearImage,
  Astyles_mercado__line__hMg3J,
  ADstyles_mercado__description__onVKz,
  AnimatedLetter,
  AnimatedLetter1,
} from './styled';



const SectionYears: React.FC = () => {
  return (

    <Astyles_mercado__j7V5j>

      <Astyles_mercado__wrapper__5ko_U>

        <Astyles_mercado__title__JXHB_>
          Estamos <br className="hidden lg:block" />
          há
          {' '}
          <Astyles_rotating__text__i37fz>
            <Astyles_word__kIEDv1>
              <AnimatedLetter1>m</AnimatedLetter1>
              <AnimatedLetter1>a</AnimatedLetter1>
              <AnimatedLetter1>i</AnimatedLetter1>
              <AnimatedLetter1>s</AnimatedLetter1>
            </Astyles_word__kIEDv1>
            <Astyles_word__kIEDv1>
              <AnimatedLetter delay="0s">m</AnimatedLetter>
              <AnimatedLetter delay="0.25s">a</AnimatedLetter>
              <AnimatedLetter delay="0.5s">i</AnimatedLetter>
              <AnimatedLetter delay="0.75s">s</AnimatedLetter>
            </Astyles_word__kIEDv1>
            <Astyles_mercado__icon__lj8Qu>
              <svg className="styles_mercado__icon__lj8Qu" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.892 23.1486V0.424561H12.532V23.1486H8.892ZM0 13.5806V10.0446H21.372V13.5806H0Z" fill="#00C6D5"></path>
              </svg>
            </Astyles_mercado__icon__lj8Qu>
          </Astyles_rotating__text__i37fz>
          {' '}
          de
        </Astyles_mercado__title__JXHB_>


        <SectionYearImage
          src={Svgs.aboutYears}
          alt="Anos no mercado"
          width="552"
          height="174"
        />

      </Astyles_mercado__wrapper__5ko_U>


      <Astyles_mercado__line__hMg3J />



      <ADstyles_mercado__description__onVKz>
        Nosso compromisso é com a entrega.
      </ADstyles_mercado__description__onVKz>

    </Astyles_mercado__j7V5j>

  );
};



export default SectionYears;
