import React from 'react';

import {
  Images,
} from '../../../shared/constants';

import {
  AboutCitiesContainer,
  AboutCitiesTitlesView,
  AboutCitiesSectionTitle,
  AboutCitiesSection,
  AboutCitiesGrid,
  AboutCitiesImageView,
  AboutCitiesImage,
  AboutCitiesTitle,
  AboutCitiesAddressText,
} from './styled';



export interface IProps {
  id?: string;
}



const SectionCities: React.FC<IProps> = (props) => {
  const cities = [
    {
      image: Images.placeBa,
      city: 'Salvador',
      place: 'Shopping da Bahia',
      address: 'Av. Antonio Carlos Magalhaes, S/N',
      state: 'Salvador, BA',
    },
    {
      image: Images.placeSp,
      city: 'São Paulo',
      place: 'Shopping do Centro',
      address: 'Brig. Faria Lima, 41',
      state: 'São Paulo, SP',
    },
    {
      image: Images.placeBh,
      city: 'Belo Horizonte',
      place: 'Shopping de BH',
      address: 'Rua Fernandes Tourinho, 147',
      state: 'Belo Horizonte, MG',
    },
  ];



  function renderCardCity(image: string, city: string, place: string, address: string, state: string) {
    return (

      <AboutCitiesGrid>
        <AboutCitiesImageView>
          <AboutCitiesImage
            alt={city}
            src={image}
          />
        </AboutCitiesImageView>

        <AboutCitiesTitle>
          {city}
        </AboutCitiesTitle>

        <AboutCitiesAddressText>
          {place}
        </AboutCitiesAddressText>

        <AboutCitiesAddressText>
          {address}
        </AboutCitiesAddressText>

        <AboutCitiesAddressText>
          {state}
        </AboutCitiesAddressText>
      </AboutCitiesGrid>

    );
  }



  return (

    <AboutCitiesContainer
      id={props.id}>

      <AboutCitiesTitlesView>
        <AboutCitiesSectionTitle>
          {'Endereços'.toUpperCase()}
        </AboutCitiesSectionTitle>
      </AboutCitiesTitlesView>



      <AboutCitiesSection>

        {cities.map((citiesItem) => renderCardCity(
          citiesItem.image,
          citiesItem.city,
          citiesItem.place,
          citiesItem.address,
          citiesItem.state,
        ))}

      </AboutCitiesSection>

    </AboutCitiesContainer>

  );
};



export default SectionCities;
