import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  TitleContents,
} from '../../../styles/styled.layout';



export const AboutCitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: ${Sizes.desktopPaddingVertical};
  background-color: ${Colors.black};
`;

export const AboutCitiesTitlesView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const AboutCitiesSectionTitle = styled(TitleContents)`
`;



export const AboutCitiesSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px;
  width: 100%;
`;

export const AboutCitiesGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
`;

export const AboutCitiesImageView = styled.div`
  width: 155px;
  height: 155px;
  margin-bottom: 16px;
`;

export const AboutCitiesImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const AboutCitiesTitle = styled.h3`
  margin: 0 0 10px 0;
  color: ${Colors.white};
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 700;
`;

export const AboutCitiesAddressText = styled.div`
  margin: 2px 0 2px 0;
  color: ${Colors.muted};
  font-size: 14px;
  font-weight: 400;
`;
