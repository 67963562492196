import React from 'react';

import {
  WeAreContainer,
  WeAreTitlesView,
  WeAreTitle,
  WeAreContent,
  WeAreLeft,
  WeAreItems,
  WeAreItIcon,
  WeAreItInfo,
  WeAreItInfoTitle,
  WeAreItInfoText,
  WeAreRight,
  WeAreNumber,
  WeAreNuIcon,
  WeAreNuInfoTitle,
  WeAreNuInfoText,
} from './styled';



export interface IProps {
  id?: string;
}



const SectionWeAre: React.FC<IProps> = (props) => {
  function renderWeAreItem(icon: any, title: string, text: string) {
    return (

      <WeAreItems>

        <WeAreItIcon>
          {icon}
        </WeAreItIcon>


        <WeAreItInfo>

          <WeAreItInfoTitle>
            {title}
          </WeAreItInfoTitle>


          <WeAreItInfoText>
            {text}
          </WeAreItInfoText>

        </WeAreItInfo>

      </WeAreItems>

    );
  };


  function renderWeAreNumber(icon: any, quantity: string, title: string) {
    return (

      <WeAreNumber>

        <WeAreNuIcon>
          {icon}
        </WeAreNuIcon>


        <WeAreNuInfoTitle>
          {quantity}
        </WeAreNuInfoTitle>


        <WeAreNuInfoText>
          {title}
        </WeAreNuInfoText>

      </WeAreNumber>

    );
  };



  return (

    <WeAreContainer
      id={props.id}>

      <WeAreTitlesView>
        <WeAreTitle>
          {'Sobre nós'.toUpperCase()}
        </WeAreTitle>
      </WeAreTitlesView>



      <WeAreContent>

        <WeAreLeft>

          {renderWeAreItem(
            '',
            'Quem somos',
            'Apaixonados por tecnologias e inovações, buscamos sempre nos atualizar e procuramos passar isso em todo o nosso ambiente.',
          )}

          {renderWeAreItem(
            '',
            'O que acreditamos',
            'Nossa visão é ser a empresa de referência em entretenimento, atuando em diversos segmentos e reconhecida como a maior e melhor opção por colaboradores, parceiros, a comunidade e principalmente pelos nossos usuários, pela nossa qualidade de nossos produtos, serviços e principalmente pelo relacionamento e a atenção com todos.',
          )}

          {renderWeAreItem(
            '',
            'O que propomos',
            'Nossa missão é atender com excelência nossos usuários com o melhor de nossos serviços e produtos que contribuam para uma experiência de diversão inesquecível nas pessoas, sempre colocando suas opiniões em primeiro plano',
          )}


        </WeAreLeft>


        <WeAreRight>

          {renderWeAreNumber(
            '',
            '+28.000.000',
            'Ingressos',
          )}

          {renderWeAreNumber(
            '',
            '+28.000.000',
            'Ingressos',
          )}

          {renderWeAreNumber(
            '',
            '+28.000.000',
            'Ingressos',
          )}

          {renderWeAreNumber(
            '',
            '+28.000.000',
            'Ingressos',
          )}

        </WeAreRight>

      </WeAreContent>

    </WeAreContainer>

  );
};



export default SectionWeAre;
