import {
  Keys,
} from '../shared/constants';



export function openSite(siteToOpen: string | null | undefined) {
  if (!siteToOpen) {
    return;
  }

  window.open(siteToOpen, '_blank')?.focus();
};



export function openAppWeb(urlForBrowser: string | null | undefined) {
  if (!urlForBrowser) {
    return;
  }

  window.open(urlForBrowser, '_blank')?.focus();
}



export function openHelpWhatsApp(message?: string) {
  const encodedMessage = message || Keys.whatsAppMessage;
  const linkWhatsAppContact = `https://api.whatsapp.com/send?phone=${Keys.whatsAppNumber}&text=${encodeURIComponent(encodedMessage)}`;

  openSite(linkWhatsAppContact);
};
