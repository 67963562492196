import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '../../../shared/constants';

import {
  TitleContents,
} from '../../../styles/styled.layout';



export const AboutNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${Sizes.desktopPaddingVertical};
  padding-left: ${Sizes.desktopPaddingHorizontal};
  padding-right: ${Sizes.desktopPaddingHorizontal};
  padding-bottom: ${Sizes.desktopPaddingVertical};
  background-color: ${Colors.appBackground};
`;

export const AboutNumbersTitlesView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const AboutNumbersTitle = styled(TitleContents)`
`;



export const AboutNumbersContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1350px;
  margin-top: ${Sizes.marginFromTitles};
`;


export const AboutNumbersGrid = styled.div`
  display: inline-grid;
  grid-gap: 2.1875rem;
  grid-template-columns: repeat(3,auto);
  align-items: flex-start;
  justify-items: center;
  width: 100%;
`;

export const AboutNumbersColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-bottom: 20px;
`;

export const AboutNumbersCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


export const AboutNumberText = styled.p`
  margin-bottom: 15px;
  padding-bottom: 10px;
  color: ${Colors.accent};
  font-size: 48px;
  font-weight: 300;
  line-height: 60px;
  text-align: center;
  border-bottom: 1px solid ${Colors.primary};
`;

export const AboutNumbersQuantityText = styled.span`
  font-size: 26px;
  line-height: 1.2;
`;

export const AboutNumbersDescription = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  text-align: center;
`;



export const AboutNumbersInvestView = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 9%;
`;

export const AboutNumbersInvestTitle = styled.h2`
  color: ${Colors.white};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
`;


export const AboutInvestCardView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const AboutInvestCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: ${Colors.white};
  border-radius: 5px;
  box-shadow: 0 2px 40px #b6afaf59;
`;

export const AboutInvestCardImageView = styled.div`
  overflow: hidden;
  height: 80px;
  min-height: 80px;
  width: 80px;
  min-width: 80px;
  margin-right: 15px;
  border-radius: 5px;
`;

export const AboutInvestCardImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const AboutInvestCardText = styled.p`
  color: ${Colors.black};
  font-size: 14px;
`;


export const AboutInvestCardFooterText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
`;
