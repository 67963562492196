import React from 'react';

import {
  AboutNumbersContainer,
  AboutNumbersTitlesView,
  AboutNumbersTitle,
  AboutNumbersContent,
  AboutNumbersGrid,
  AboutNumbersColumn,
  AboutNumbersCard,
  AboutNumberText,
  AboutNumbersQuantityText,
  AboutNumbersDescription,
  AboutNumbersInvestView,
  AboutNumbersInvestTitle,
  AboutInvestCardView,
  AboutInvestCards,
  AboutInvestCardImageView,
  AboutInvestCardImage,
  AboutInvestCardText,
  AboutInvestCardFooterText,
} from './styled';



export interface IProps {
  id?: string;
}



const SectionOurNumbers: React.FC<IProps> = (props) => {
  const numbers = [
    {
      title: '65',
      quantity: 'Milhões',
      description: 'Pedidos entregues todo mês',
    },
    {
      title: '+60',
      quantity: 'Mil',
      description: 'Mercados, em 400 cidades',
    },
    {
      title: '+1700',
      quantity: null,
      description: 'Cidades em todo o Brasil',
    },
    {
      title: '+1.5',
      quantity: 'Milhões',
      description: 'Downloads do nosso App por mês',
    },
    {
      title: '+300',
      quantity: 'Mil',
      description: 'Restaurantes parceiros',
    },
    {
      title: '+200',
      quantity: 'Mil',
      description: 'Entregadores ativos na plataforma',
    },
    {
      title: '6',
      quantity: 'Mil',
      description: 'Colaboradores ou FoodLovers',
    },
  ];

  const investors = [
    {
      name: 'BMW',
      image: 'https://di-uploads-pod16.dealerinspire.com/competitionbmwofsmithtown/uploads/2020/03/new-bmw-logo.jpg',
      description: 'Investe e desenvolve negócios de tecnologia.',
    },
  ];



  return (

    <AboutNumbersContainer
      id={props.id}>

      <AboutNumbersTitlesView>
        <AboutNumbersTitle>
          {'Nossos números'.toUpperCase()}
        </AboutNumbersTitle>
      </AboutNumbersTitlesView>



      <AboutNumbersContent>

        <AboutNumbersGrid>
          {numbers.map((numberCard) => (

            <AboutNumbersColumn>
              <AboutNumbersCard>

                <AboutNumberText>
                  {numberCard.title}

                  {numberCard.quantity && (
                    <AboutNumbersQuantityText>
                      &nbsp;
                      {numberCard.quantity}
                    </AboutNumbersQuantityText>
                  )}
                </AboutNumberText>

                <AboutNumbersDescription>
                  {numberCard.description}
                </AboutNumbersDescription>
              </AboutNumbersCard>
            </AboutNumbersColumn>

          ))}
        </AboutNumbersGrid>


        <AboutNumbersInvestView>
          <AboutNumbersInvestTitle>
            Investidores
          </AboutNumbersInvestTitle>


          <AboutInvestCardView>
            {investors.map((investorsCard) => (

              <AboutInvestCards>
                <AboutInvestCardImageView>
                  <AboutInvestCardImage
                    alt="Card"
                    src={investorsCard.image}
                  />
                </AboutInvestCardImageView>


                <AboutInvestCardText>
                  {investorsCard.description}
                </AboutInvestCardText>
              </AboutInvestCards>

            ))}
          </AboutInvestCardView>


          <AboutInvestCardFooterText>
            Temos orgulho da nossa história, de quem está e de quem esteve conosco em nossa caminhada.
          </AboutInvestCardFooterText>
        </AboutNumbersInvestView>

      </AboutNumbersContent>

    </AboutNumbersContainer>

  );
};



export default SectionOurNumbers;
